import { carTrim } from '@services/trim';
import { isFirefox, shouldSendSameSiteNone } from '@services/browserHelper';

/**
 * Создание управляющего iframe для дальнейшего подключения в нем скрипта
 */
function createProxyIframe() {
  let iframe = document.createElement("iframe");

  iframe.setAttribute('id', 'proxy-iframe');
  iframe.setAttribute('name', 'proxy-iframe');
  // !!! display: block стоит не просто так, а из-за того, что на Firefox при display: none у iframe перестаёт корректно работать Svelte
  iframe.setAttribute('style', 'display: block !important; width: 0 !important; height: 0 !important; border: none !important; position: absolute !important; top: 0 !important; left: 0 !important; visibility: hidden !important;');

  iframe.addEventListener('load', function () {

    // Добавляем тег со скриптом в созданном iframe
    createScriptIframe();
    createSvelteChat();
    createSvelteLauncher();
  }, {once: true});

  document.body.appendChild(iframe);

  /**
   * Подключение скрипта в iframe, проксирование глобальных методов
   */
  function createScriptIframe() {

    let indexScript = document.createElement("script");

    indexScript.type = "text/javascript";
    indexScript.async = true;

    let src = `${import.meta.env.VITE_CDN_HOST}/index${import.meta.env.VITE_FILE_NAME_SUFFIX}.js`;

    indexScript.src = src;
    indexScript.type = 'module';

    let proxyIframe = document.getElementById('proxy-iframe').contentWindow;
    proxyIframe.document.head.appendChild(indexScript);

    // Функция выполнения пользовательского кода из iframe на странице сайта
    window.parent.dashlyExecuteEval = (code) => {
      eval(code);
    }
  }

  /**
   * Подключение svelte в iframe
   */
  function createSvelteChat() {
    let tag = document.createElement("script");
    let svelteSrc;

    svelteSrc = `${import.meta.env.VITE_CDN_HOST}/chat${import.meta.env.VITE_FILE_NAME_SUFFIX}.js`;

    tag.src = svelteSrc;
    tag.type = 'module';

    let proxyIframe = document.getElementById('proxy-iframe').contentWindow;
    proxyIframe.document.head.appendChild(tag);
  }

  /**
   * Подключение svelte в iframe
   */
  function createSvelteLauncher() {
    let tag = document.createElement("script");
    let svelteSrc;

    svelteSrc = `${import.meta.env.VITE_CDN_HOST}/launcher${import.meta.env.VITE_FILE_NAME_SUFFIX}.js`;

    tag.src = svelteSrc;
    tag.type = 'module';

    let proxyIframe = document.getElementById('proxy-iframe').contentWindow;
    proxyIframe.document.head.appendChild(tag);
  }
}

createProxyIframe();
